import React from 'react'
import logo from '../../assets/logo.png';
import MapContainer from '../../Components/map';
export default function Contact() {

    return (<>
        <section className="contact">
            <div className="contact__container">
                <header className="contact__header">
                    <h2 className="contact__title">Kontakt</h2>
                    <p className="contact__text">Zapraszamy do kontaktu i rezerwacji stolika:</p>
                </header>
                <div className="contact__adress">
                    <span>Restauracja Pod Jabłonią</span>
                    <span>Aleja Pokoju 60</span>
                    <span>31-564 Kraków</span>

                </div>
                <div className="contact__mailTel">
                    <a href="tel:+48786934146">+ 48 786 934 146</a>
                    <a href="mailto:podjablonia.biuro@gmail.com">podjablonia.biuro@gmail.com</a>
                </div>
                <div className="contact__location">
                    <p>
                        <b>Jak do nas trafić?</b>
                        <br />Zjeżdżając z Alei Pokoju wjeżdżacie na stacje benzynową Orlen
                        i dalej za naszym znakiem skręcacie w prawo.
                        <br />Mała dróżka doprowadzi was do białej bramy naszego ogrodu.
                        <br />
                        Istnieje także możliwość podejścia od strony Tauron Areny, wchodząc przez nasz
                        ogród.
                        <br />
                        Śledźcie naszą mapkę dojazdu.
                    </p>
                </div>

                <div className="contact__logo">
                    <img src={logo} alt="logo restauracja pod jabłonią" />
                </div>
            </div>

        </section>
        <div>
            <MapContainer />

        </div>
    </>
    )
}

// ? Mapka dojazdu(w trakcie pracy) Godziny otwarcia : Codziennie 13 : 00 - 20 :
// 00 (na czas pandemii)
// background-position: 40%;