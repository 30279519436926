
import React, { Component } from 'react'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
const mapStyles = {
    width: '100%',
    height: '100%',
    
};
class MapContainer extends Component {
    render() {
        return (
            <Map className="mapgoogle"
                onClick={() => window.location = "https://www.google.pl/maps/place/Pod+Jab%C5%82oni%C4%85/@50.065682,19.9910057,17z/data=!3m1!4b1!4m5!3m4!1s0x4716457d05dafc69:0xe1c48e6a3e098cf5!8m2!3d50.0656786!4d19.9931944"}
                google={this.props.google}
                zoom={17}
                style={mapStyles}
                initialCenter={{ lat: 50.065703, lng: 19.993179 }}>
                <Marker position={{ lat: 50.065703, lng: 19.993179 }} />
            </Map>
        );
    }
}
export default GoogleApiWrapper({
    apiKey: 'AIzaSyBdcLKGOQ4QDxmNqmR5VGju78I_UR33od4'
})(MapContainer);

// apiKey={''}