import React from 'react'
import img from '../../assets/zdjecia/events.JPG'
export default function Events() {
    return (
        <div className="events">
            <h2 className="events__title">WYDARZENIA</h2>
            <figure className="events__img">
                <img src={img} alt="" />
            </figure>
            <p className="events__text">
                Zapraszamy Was do organizowania w restauracji „Pod Jabłonią” spotkań w gronie przyjaciół, rodzinnych imprez okolicznościowych i wydarzeń biznesowych.
                Każdy kto szuka wyjątkowego miejsca pełnego naturalnego klimatu, spokoju i profesjonalnego podejścia – znajdzie to u nas.
                Nasi kucharze oraz obsługa dochowają wszelkich starań, aby Wasze wydarzenie było przyjemnym i zapamiętanym na długo spotkaniem.
            </p>
            <div className="events__wrapper">
                <h4 className="events__subTitle">Organizacja imprez:</h4>
                <div className="events__contact">tel.: 786 934 145</div>
                <div className="events__contact">mail: podjablonia.biuro@gmail.com</div>
            </div>
            <div className="events__info">Spotkania organizacyjne w sprawie imprez okolicznościowych odbywają się w poniedziałki, środy i piątki.</div>
        </div>
    )
}
