import React from 'react'

import cRight from '../../assets/icon/chevronRight.svg'
import cLeft from '../../assets/icon/chevronLeft.svg'

export default function ImageFullSize({ src, close, prev, next }) {
    return (
        <div onClick={close} className="imageFullSize">
            <div className="imageFullSize__container">
                <figure className="imageFullSize__imageContainer">
                    <div onClick={(e) => prev(e)} className="imageFullSize__arrow l">
                        <figure className="imageFullSize__icon">
                            <img src={cLeft} alt="" />
                        </figure>
                    </div>
                    <img className="imageFullSize__image" src={`https://api.restauracjapodjablonia.pl${src}`} alt="gallery" />
                    <div onClick={(e) => next(e)} className="imageFullSize__arrow r">
                        <figure className="imageFullSize__icon">
                            <img src={cRight} alt="" />
                        </figure>
                    </div>
                </figure>
            </div>
        </div>
    )
}
